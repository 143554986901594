import axios from "axios";
delete axios.defaults.headers.common["x-access-token"];
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Token'] = process.env.REACT_APP_Api_Token;
const API_URL = process.env.REACT_APP_API_URL;
// Get Russell Stocks
const GetRussellStocks = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}russell`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Russell Stocks
const GetSingelStocks = (id, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}getinfo?id=${id}`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Russell Stocks
const GetTopSingelStocks = (id, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}getinfo_top?id=${id}`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}


// Get Top trade Stocks
const GetTopTradeStocks = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}top`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Top trade Stocks
const GetFiveDayStocks = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}get_five`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Dates
const GetDates = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}getdate`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Value-based Dynamic Portfolio
const GetValueBasedDynamicPortfolio = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Value`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Momentum-based Dynamic Portfolio
const GetMomentumBasedDynamicPortfolio = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Momentum`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Momentum-based Dynamic Portfolio
const GetCriticalData = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}critical`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Momentum-based Dynamic Portfolio
const GetCriticalWorldStockData = ( cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}Country_Funda`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Momentum-based Dynamic Portfolio
const GetValuationData = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Valuation`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}


// Get quarterly_forecast
const GetQuarterlyForecast = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}quarterly_forecast`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Refresh Token
const RefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios.get(`${API_URL}refresh`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Momentum-based Dynamic Portfolio
const GetIntra = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Intra`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Loger api
const UserLogger = (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}user_analytics`, data, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Subscriptions
const GetSubscriptions = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios.get(`${API_URL}subscription_info`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Penny Stocks Tradeable
const GetPennyStocks = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios.get(`${API_URL}penny_stocks`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Add user Subscription
// const UserSubscriptionAdd = (data) => {
//     const user = JSON.parse(localStorage.getItem("user"));
//     return axios.post(`${API_URL}make_subscription`, data, {
//         headers: {
//             'x-access-token': user?.token,
//             'Uid': user?.uid
//         }
//     })
// }

// Add user Subscription
// const UserSubscriptionAdd = (data) => {
//     const user = JSON.parse(localStorage.getItem("user"));
//     return axios.post(`${API_URL}user_subscription`, data, {
//         headers: {
//             'x-access-token': user?.token,
//             'Uid': user?.uid
//         }
//     })
// }

// Get Top 50 Most Predictable Forecasts
const Getintra_volatility = (cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}intra_volatility`, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}


// Add user Subscription
const UserSubscriptionPaymentAdd = (data) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}make_subscription`, data, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Add user Subscription
const SubscriptionConfirm = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}subscription_confirm`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// get order subscription
const Userlist_user_subscription = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}list_user_subscription`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Commodities
const GetCommodities = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}etf_commodity`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Commodities
const Getpaymentinfo = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}get_payment_info`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Commodities
const Getdashboard_info = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}dashboard_info`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Inovation Stocks Dynamic Portfolio
const GetInovationStocksDynamicPortfolio = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Innovation`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Small cap
const GetSmallCapPortfolio = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}SmallCap`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Small cap
const GetSmallCapPortfolioVT = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}SmallCap_VT`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Small cap
const GetInnovation_VT = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Innovation_VT`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Profitability 
/* const GetProfitability = (data, cancel) => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.post(`${API_URL}Profitability`, data, {
        cancelToken: cancel,
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
} */

// Get Profitability
const GetProfitability = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}Profitability`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Get Profitability
const GetMost_profitable = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return axios.get(`${API_URL}most_profitable`, {
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GetRussellStocks,
    GetSingelStocks,
    GetTopTradeStocks,
    GetTopSingelStocks,
    GetFiveDayStocks,
    GetDates,
    GetValueBasedDynamicPortfolio,
    GetMomentumBasedDynamicPortfolio,
    GetCriticalData,
    GetValuationData,
    GetQuarterlyForecast,
    RefreshToken,
    GetIntra,
    UserLogger,
    GetSubscriptions,
    GetPennyStocks,
    Getintra_volatility,
    UserSubscriptionPaymentAdd,
    SubscriptionConfirm,
    Userlist_user_subscription,
    GetCommodities,
    Getpaymentinfo,
    Getdashboard_info,
    GetInovationStocksDynamicPortfolio,
    GetSmallCapPortfolio,
    GetSmallCapPortfolioVT,
    GetInnovation_VT,
    GetProfitability,
    GetMost_profitable,
    GetCriticalWorldStockData
}