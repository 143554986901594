import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Loader from './Component/Loader';
import Header from './Component/Header';
import Footer from './Component/Footer';
import UserReducer from './Service/UserReducer';
import { Modal, Button } from 'react-bootstrap';
import Authservice from './Service/Authservice';
import { toast, ToastContainer } from 'react-toastify';
import stockService from './Service/stock.service';

const Home = React.lazy(() => import('./Pages/Home'));
const RussellStocks = React.lazy(() => import('./Pages/Forecasting/RussellStocks'));
const TopPicksForDay = React.lazy(() => import('./Pages/Forecasting/Top_Picks_For_Day'));
const SignIn = React.lazy(() => import('./Pages/SignIn'));
const SignUp = React.lazy(() => import('./Pages/SignUp'));
const ForgotPassword = React.lazy(() => import('./Pages/ForgotPassword'));
const ConfirmAccount = React.lazy(() => import('./Pages/ConfirmAccount'));
const StockDetails = React.lazy(() => import('./Pages/Forecasting/StockDetails'));
const TopStockDetails = React.lazy(() => import('./Pages/Forecasting/TopStockDetails'));
const About = React.lazy(() => import('./Pages/About'));
const TeramsCondition = React.lazy(() => import('./Pages/TeramsCondition'));
const PrivacyPolicy = React.lazy(() => import('./Pages/PrivacyPolicy'));
const NoFOundPage = React.lazy(() => import('./Pages/NoFOundPage'));
const ForgotChangePassword = React.lazy(() => import('./Pages/ForgotChangePassword'));
const TopFiveDay = React.lazy(() => import('./Pages/Forecasting/Top_Five_Day'));
const ValuebasedDynamicPortfolio = React.lazy(() => import('./Pages/Trading/ValuebasedDynamicPortfolio'));
const MomentumbasedDynamicPortfolio = React.lazy(() => import('./Pages/Trading/MomentumbasedDynamicPortfolio'));
const CriticalFundamentalsQuarterly = React.lazy(() => import('./Pages/Analysis/CriticalFundamentalsQuarterly'));
const CriticalFundamentalsQuarterlyWorldStocks = React.lazy(() => import('./Pages/Analysis/CriticalFundamentalsQuarterlyWorldStocks'));
const CriticalFundamentalsWeekly = React.lazy(() => import('./Pages/Analysis/CriticalFundamentalsWeekly'));
const SnPHighestMarginSafety = React.lazy(() => import('./Pages/Analysis/SnPHighestMarginSafety'));
const SnPHighestMomentum = React.lazy(() => import('./Pages/Analysis/SnPHighestMomentum'));
const SnPHighestRevenueGrowth = React.lazy(() => import('./Pages/Analysis/SnPHighestRevenueGrowth'));
const SnPHighestValueCreation = React.lazy(() => import('./Pages/Analysis/SnPHighestValueCreation'));
const IntraVolatility = React.lazy(() => import('./Pages/Analysis/IntraVolatility'));
const QuarterlyForecast = React.lazy(() => import('./Pages/Forecasting/QuarterlyForecast'));
const PennyStock = React.lazy(() => import('./Pages/Analysis/PennyStock'));
const Subscription = React.lazy(() => import('./Pages/Subscription'));
const Checkout = React.lazy(() => import('./Pages/Checkout'));
const Profile = React.lazy(() => import('./Pages/Profile'));
const Payment = React.lazy(() => import('./Pages/Payment'));
const Commodities = React.lazy(() => import('./Pages/Forecasting/Commodities'));
const SnP1500Dashboard = React.lazy(() => import('./Pages/Analysis/SnP1500Dashboard'));
const MostProfitableForDayTradeDetailsChart = React.lazy(() => import('./Pages/Analysis/MostProfitableForDayTradeDetailsChart'));
const SubscriptionConfirmation = React.lazy(() => import('./Pages/SubscriptionConfirmation'));
const InovationStocksDynamicPortfolio = React.lazy(() => import('./Pages/Trading/InnovationStocksDynamicPortfolio'));
const SmallCapPortfolio = React.lazy(() => import('./Pages/Trading/SmallCapPortfolio'));
const InnovationStocksDynamicPortfolioVolatility = React.lazy(() => import('./Pages/Trading/InnovationStocksDynamicPortfolioVolatility'));
const SmallCapPortfolioVolatility = React.lazy(() => import('./Pages/Trading/SmallCapPortfolioVolatility'));
const SiteMap = React.lazy(() => import('./Pages/SiteMap'));
const UnderConstraction = React.lazy(() => import('./Pages/UnderConstraction'));


export const AuthContext = React.createContext();

const hasuser = JSON.parse(localStorage.getItem('user'));
const initialState = {
  isAuthenticated: (hasuser !== null && hasuser.Success === true && hasuser.Verified === true) ? true : false,
  user: hasuser,
  token: null,
  redirectUrl: null,
  LogoutInProcess: false,
  NotVerify: false,
  refreshTimer: null,
};


function App() {
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(UserReducer, initialState);
  const [SubscriptionEnd, SetSubscriptionEnd] = useState(false);


  useEffect(() => {
    const time = new Date(localStorage.getItem("T"));
    const current = new Date();
    const sec = (time.getTime() - current.getTime()) / 1000;
    if (current <= time && state.isAuthenticated === true) {
      state.refreshTimer = setInterval(() => RefreshToken(), sec * 1000);
    }

    SetSubscriptionEnd(+state?.user?.Sub_days_remaining <= 8 ? true : false)

    return () => { clearInterval(state.refreshTimer); }
  }, [state.isAuthenticated, state.refreshTimer]);


  const resendMail = () => {
    let vdata = {
      uid: state?.user?.uid
    }
    Authservice.ResendUserVerify(vdata, undefined)
      .then(res => {
        if (res.data.Success) {
          toast.success(res.data.Message);
          dispatch({ type: "VERIFICATION", payload: false })
        }
        else {
          toast.error(res.data.Message);
          dispatch({ type: "VERIFICATION", payload: false })
        }

      })
      .catch(err => {
        toast.error("Some problem occurred!! Please try again later");
        if (err.response.status === 401 || err.response.status === 403) {
          navigate('/')
          toast.error(err.response.data.Msg);
        }
        dispatch({ type: "VERIFICATION", payload: false })
      });
  }

  const closeResend = () => {
    dispatch({ type: "VERIFICATION", payload: false })
    dispatch({ type: "LOGOUT", payload: false })
  }

  const RefreshToken = () => {
    if (state.isAuthenticated === true) {
      var d = new Date(); // get current date
      d.setHours(d.getHours(), d.getMinutes() + 17.5, 0, 0);
      localStorage.setItem("T", d.toISOString());
      stockService.RefreshToken()
        .then(res => {

          const user = JSON.parse(localStorage.getItem("user"));
          user['token'] = res.data.Token;
          localStorage.setItem("user", JSON.stringify(user))
          dispatch({ type: "TOCKENUPDATE", payload: user })
        })
        .catch(err => {
          if (err?.response?.status === 401 || err?.response?.status === 403) {
            dispatch({ type: "LOGOUT", payload: false })
            navigate('/sign-in')
            toast.error(err.response.data.Msg);
          } else {
            toast.error("Some problem occurred!! Please try again later");
          }
        });
    }

  }
  return (
    <>
      <AuthContext.Provider value={{ state, dispatch }}>
        <Header />
        <ToastContainer
          position="bottom-right"
          autoClose={7000} />
        <Routes>
          <Route path='/'
            element={<React.Suspense fallback={<Loader />}><Home /></React.Suspense>} />

          <Route path='/top-picks-for-day-trade'
            element={<React.Suspense fallback={<Loader />}><TopPicksForDay /> </React.Suspense>} />

          <Route path='/russells-stocks'
            element={<React.Suspense fallback={<Loader />}><RussellStocks /> </React.Suspense>} />

          <Route path='/sign-in'
            element={<React.Suspense fallback={<Loader />}><SignIn /> </React.Suspense>} />

          <Route path='/sign-up'
            element={<React.Suspense fallback={<Loader />}><SignUp /> </React.Suspense>} />

          <Route path='/forgot-password'
            element={<React.Suspense fallback={<Loader />}><ForgotPassword /> </React.Suspense>} />

          <Route path='/active-account/:uid/:token'
            element={<React.Suspense fallback={<Loader />}><ConfirmAccount /> </React.Suspense>} />

          <Route path='/stockinfo/:id'
            element={<React.Suspense fallback={<Loader />}><StockDetails /> </React.Suspense>} />

          <Route path='/topstockinfo/:id'
            element={<React.Suspense fallback={<Loader />}><TopStockDetails /> </React.Suspense>} />

{/*           <Route path='/about'
            element={<React.Suspense fallback={<Loader />}><About /> </React.Suspense>} /> */}

          <Route path='/terms-condition'
            element={<React.Suspense fallback={<Loader />}><TeramsCondition /> </React.Suspense>} />

          <Route path='/privacy-policy'
            element={<React.Suspense fallback={<Loader />}><PrivacyPolicy /> </React.Suspense>} />

          <Route path='/reset-password/:uid/:token'
            element={<React.Suspense fallback={<Loader />}><ForgotChangePassword /> </React.Suspense>} />

          <Route path='/five-days:'
            element={<React.Suspense fallback={<Loader />}><TopFiveDay /> </React.Suspense>} />

          <Route path='/value-based-dynamic-portfolio'
            element={<React.Suspense fallback={<Loader />}><ValuebasedDynamicPortfolio /> </React.Suspense>} />

          <Route path='/momentum-based-dynamic-portfolio'
            element={<React.Suspense fallback={<Loader />}><MomentumbasedDynamicPortfolio /> </React.Suspense>} />

          <Route path='/critical-fundamentals-quarterly:'
            element={<React.Suspense fallback={<Loader />}><CriticalFundamentalsQuarterly /> </React.Suspense>} />

          <Route path='/critical-fundamentals-quarterly-world-stocks:'
            element={<React.Suspense fallback={<Loader />}><CriticalFundamentalsQuarterlyWorldStocks /> </React.Suspense>} />

          <Route path='/critical-fundamentals-weekly'
            element={<React.Suspense fallback={<Loader />}><CriticalFundamentalsWeekly /> </React.Suspense>} />

          <Route path='/snp-highest-margin-safety'
            element={<React.Suspense fallback={<Loader />}><SnPHighestMarginSafety /> </React.Suspense>} />

          <Route path='/snp-highest-momentum'
            element={<React.Suspense fallback={<Loader />}><SnPHighestMomentum /> </React.Suspense>} />

          <Route path='/snp-highest-revenue-growth'
            element={<React.Suspense fallback={<Loader />}><SnPHighestRevenueGrowth /> </React.Suspense>} />

          <Route path='/snp-highest-value-creation'
            element={<React.Suspense fallback={<Loader />}><SnPHighestValueCreation /> </React.Suspense>} />

          <Route path='/quarterly-forecast:'
            element={<React.Suspense fallback={<Loader />}><QuarterlyForecast /> </React.Suspense>} />

          <Route path='/pennystock'
            element={<React.Suspense fallback={<Loader />}><PennyStock /> </React.Suspense>} />

          <Route path='/intra-volatility'
            element={<React.Suspense fallback={<Loader />}><IntraVolatility /> </React.Suspense>} />

          <Route path='/subscriptions'
            element={<React.Suspense fallback={<Loader />}><Subscription /> </React.Suspense>} />

          <Route path='/checkout'
            element={<React.Suspense fallback={<Loader />}><Checkout /> </React.Suspense>} />

          <Route path='/make-payment'
            element={<React.Suspense fallback={<Loader />}><Payment /> </React.Suspense>} />

          <Route path='/SubscriptionConfirmation'
            element={<React.Suspense fallback={<Loader />}><SubscriptionConfirmation /> </React.Suspense>} />

          <Route path='/profile'
            element={<React.Suspense fallback={<Loader />}><Profile /> </React.Suspense>} />

          <Route path='/my-subscriptions'
            element={<React.Suspense fallback={<Loader />}><Profile /> </React.Suspense>} />

          <Route path='/change-password'
            element={<React.Suspense fallback={<Loader />}><Profile /> </React.Suspense>} />

          <Route path='/my-wallet'
            element={<React.Suspense fallback={<Loader />}><Profile /> </React.Suspense>} />

          <Route path='/all-tranasactions'
            element={<React.Suspense fallback={<Loader />}><Profile /> </React.Suspense>} />

          <Route path='/commodities'
            element={<React.Suspense fallback={<Loader />}><Commodities /> </React.Suspense>} />

          <Route path='/SnP1500Dashboard:'
            element={<React.Suspense fallback={<Loader />}><SnP1500Dashboard /> </React.Suspense>} />

          <Route path='/inovation-stocks-dynamic-portfolio'
            element={<React.Suspense fallback={<Loader />}><InovationStocksDynamicPortfolio /> </React.Suspense>} />

          <Route path='/inovation-stocks-dynamic-portfolio-volatility'
            element={<React.Suspense fallback={<Loader />}><InnovationStocksDynamicPortfolioVolatility /> </React.Suspense>} />

          <Route path='/small-cap-portfolio'
            element={<React.Suspense fallback={<Loader />}><SmallCapPortfolio /> </React.Suspense>} />

          <Route path='/small-cap-portfolio-volatility'
            element={<React.Suspense fallback={<Loader />}><SmallCapPortfolioVolatility /> </React.Suspense>} />
            
            <Route path='/most-profitable-detail/:id'
            element={<React.Suspense fallback={<Loader />}><MostProfitableForDayTradeDetailsChart /> </React.Suspense>} />

          <Route path='/site-map'
            element={<React.Suspense fallback={<Loader />}><SiteMap /> </React.Suspense>} />
            
          <Route path='/under-constraction'
            element={<React.Suspense fallback={<Loader />}><UnderConstraction /> </React.Suspense>} />

          <Route path='*'
            element={<React.Suspense fallback={<Loader />}><NoFOundPage /> </React.Suspense>} />

        </Routes>
        <Footer></Footer>

        <Modal
          show={state.LogoutInProcess}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            <p className='activation_progress logout_progress'>Logout</p>
          </Modal.Body>
        </Modal>

        <Modal
          show={state.NotVerify}
          keyboard={false}
          centered
          onHide={closeResend}
        >
          <Modal.Body>
            <div className='not_verifi'>
              <h4>Your Account Is Not Verified!!</h4>
              <p className='text-center'>We have sent you a verification link to your email account. Please verify your account.</p>
              <p>Did not recieve verification link? <span onClick={() => resendMail()}>Resend verification link</span></p>
            </div>
          </Modal.Body>
        </Modal>


        <Modal
          show={SubscriptionEnd}
          keyboard={false}
          centered
          onHide={() => SetSubscriptionEnd(false)}
        >
          <Modal.Body>
            <Button className='btn-close' type='button' onClick={() => SetSubscriptionEnd(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </Button>
            <p className='text-center subscriptio-modal-p'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#eec422" className="bi bi-bell-fill mb-3" viewBox="0 0 16 16">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
            </svg><br />Your <strong>{state?.user?.Subscription?.replace("_", ' ')}</strong> subscription will expire in <strong>{state?.user?.Sub_days_remaining} days</strong>.</p>

          </Modal.Body>
        </Modal>

        <Modal>
          <Modal.Body className='text-center'>
            <p className='text-center subscriptio-modal-p'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#eec422" className="bi bi-bell-fill mb-3" viewBox="0 0 16 16">
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
            </svg><br />your 3 days free subscription has expired.</p>
            <button class="payment-button btn btn-primary text-center" type="submit">Subscribe now</button>

          </Modal.Body>
        </Modal>

      </AuthContext.Provider>
    </>
  );
}

export default App;
