import React from 'react';
import { Link } from "react-router-dom";


import { Col, Container, Row } from 'react-bootstrap';

const Footer = () => {
    const date = new Date().getFullYear();
    return (
        <>
            <footer className='footer'>
                <Container>
                    <Row className='justify-content-between'>

                        {/* <Col lg={5}>
                            <div className='footer_logo_sec'>
                                <img src='/images/logo.png' alt='Ai Labs Market Genie' className='img-fluid' />
                                <p>Artificial Intelligence Without Borders</p>
                            </div>
                        </Col> */}

                        <Col lg={5}>
                            {/* <h4>Information</h4> */}
                            <ul>
                                <li>
                                    <Link to={'/terms-condition'} >Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to={'/privacy-policy'} >Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to={'/site-map'} >Site Map</Link>
                                </li>
                            </ul>
                        </Col>

                        <Col lg={7}>
                            {/* <h4>Contact Us</h4> */}
                            <ul className='footer_contact justify-content-end'>
                                <li>
                                    <a href='mailto:ai-fin.support@ailabs.finance'>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                            </svg>
                                        </span>
                                        ai-fin.support@ailabs.finance
                                    </a>
                                </li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                    </svg>
                                    <span className='footer-icon-margin'>111 Sinclair Rd., Bristol, PA 19007</span>
                                </li>
                            </ul>
                        </Col>

                    </Row>

                    {/* <Row className='mt-2'>
                        <Col>
                            <hr></hr>
                            <p className='copyright'>Copyright &copy; {date}  AiLabs</p>
                        </Col>
                    </Row> */}
                </Container>
            </footer>
        </>
    )


}


export default Footer;