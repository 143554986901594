import axios from "axios";
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Token'] = process.env.REACT_APP_Api_Token;
const API_URL = process.env.REACT_APP_API_URL;

// SignUp
const UserSignUp = (data,cancel) => {
    return axios.post(`${API_URL}signup`,data, {
        cancelToken: cancel
    })
}

// SignIn
const UserLogin = (data,cancel) => {
    return axios.post(`${API_URL}login`,data, {
        cancelToken: cancel
    })
}

// User account verify
const UserVerify = (data,cancel) => {
    return axios.post(`${API_URL}verify`,data, {
        cancelToken: cancel
    })
}

// Resend verify mail
const ResendUserVerify = (data,cancel) => {
    return axios.post(`${API_URL}resend`,data, {
        cancelToken: cancel
    })
}

// ogout User
const Logout = () => {
    const user = JSON.parse(localStorage.getItem("user"))
    const data = 
    {
        uid: user?.uid
    }
    return axios.post(`${API_URL}logout`,data,{
        headers: {
            'x-access-token': user?.token,
            'Uid': user?.uid
        }
    })
}

// Reset password
const ResetPassword = (data,cancel) => {
    return axios.post(`${API_URL}reset_pass`,data, {
        cancelToken: cancel
    })
}



// eslint-disable-next-line import/no-anonymous-default-export
export default {
    UserSignUp,
    UserLogin,
    UserVerify,
    ResendUserVerify,
    Logout,
    ResetPassword
}