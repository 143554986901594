
const UserReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.payload));
            const islogged = action.payload.Success === true && action.payload.Verified === true ? true : false;
            var d = new Date(); // get current date
            d.setHours(d.getHours(), d.getMinutes() + 17.5, 0, 0);
            localStorage.setItem("T", d.toISOString());
            return {
                ...state,
                isAuthenticated: islogged,
                user: action.payload,
            };
        case "LOGOUT":
            localStorage.removeItem("user")
            localStorage.removeItem("T")
            clearInterval(state.refreshTimer)
            return {
                ...state,
                isAuthenticated: action.payload,
                user: null,
                refreshTimer: null
            };
        case "REDIRECT":
            return {
                ...state,
                redirectUrl: action.payload,
            };
        case "LOGOUTPROCESS":
            return {
                ...state,
                LogoutInProcess: action.payload,
            };
        case "VERIFICATION":
            return {
                ...state,
                NotVerify: action.payload,
            };
        case "TOCKENUPDATE":
            return {
                ...state,
                user: action.payload,
            };
        case "SUBSCRIPTIONUPDATE":

            localStorage.setItem("user", JSON.stringify(action.payload));
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
};

export default UserReducer;