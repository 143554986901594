import React from 'react';
import { Spinner } from 'react-bootstrap';
const Loader = () => {
    return (
        <>
            <section style={{ height: 'calc(100vh - 170px)', textAlign: 'center' }}>
                <div className='d-flex justify-content-center align-items-center' style={{height:'100%'}}>
                    <Spinner animation="grow" />
                </div>

            </section>
        </>
    )


}


export default Loader;